import React from 'react';

import PageTitleBar from '../../components/misc/PageTitleBar';
import Form from '../../components/Contact/Recruit/Form';

const Page = () => (
  <div>
    <PageTitleBar title="Recruit" backgroundImage={require('../../assets/images/banner/voc.jpg')} />
    <Form />
  </div>
);

export default Page;
