import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';
import axios from 'axios';

import styles from './Form.module.scss';
import StoreSelector from './StoreSelector';
import Agreement from '../common/Agreement';

const cx = classNames.bind(styles);
const types = ['홀', '베이커리', '조리'];
const roles = ['신입', '경력'];

class RecruitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: 0,
      selectedRole: 0,
      isAgree: false,
      isProcessing: false,
    };

    // Refs.
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.telInput = React.createRef();
    this.contentInput = React.createRef();
  }

  render() {
    const { selectedType, selectedRole, isAgree, isProcessing } = this.state;

    return (
      <div className={cx('contentWrapper')}>
        <Container>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }} className={cx('column')}>
              <div className={cx('items')}>
                {types.map((type, index) => (
                  <div
                    key={index}
                    className={cx('item', { selected: selectedType === index })}
                    onClick={this.selectBrand.bind(this, index)}>
                    {selectedType === index && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                    <div className={cx('text')}>{type}</div>
                  </div>
                ))}
              </div>
              <div className={cx('items')}>
                {roles.map((role, index) => (
                  <div
                    key={index}
                    className={cx('item', { selected: selectedRole === index })}
                    onClick={this.selectRole.bind(this, index)}>
                    {selectedRole === index && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                    <div className={cx('text')}>{role}</div>
                  </div>
                ))}
              </div>
              <StoreSelector onRef={ref => (this.storeSelector = ref)} disabled={isProcessing} />
              <input
                type="text"
                ref={this.nameInput}
                placeholder="성명"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.emailInput}
                placeholder="이메일"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.telInput}
                placeholder="전화번호 ('-' 제외 및 숫자만 입력)"
                disabled={isProcessing}
                className={cx('field')}
              />
              <textarea
                type="text"
                ref={this.contentInput}
                placeholder="지원동기"
                className={cx('textarea')}
                rows={8}
                disabled={isProcessing}></textarea>
            </Col>
            <Col xs={12} lg={4.5} offset={{ lg: 0.5 }} className={cx('column')}>
              <div className={cx('term')}>
                <div className={cx('title')}>[자격요건]</div>
                <p className={cx('content')}>
                  해당 직무에 관심이 많으며, 서비스 마인드가 우수한 자
                  <br />
                  <span className={cx('bold')}>우대조건</span>
                  <br />
                  - 관련 직무 자격증 소지자
                  <br />- 직영점 운영 브랜드 및 베이커리 취급 브랜드 경력자
                </p>
              </div>
              <div className={cx('term')}>
                <div className={cx('title')}>[복리후생]</div>
                <p className={cx('content')}>
                  - 4대보험(국민연금, 건강보험, 고용보험, 산재보험)
                  <br />
                  - 휴가제도(연차, 출산휴가, 백신휴가)
                  <br />
                  - 경조사 지원(화환, 경조사비, 경조휴가)
                  <br />
                  - 자사, 계열사 할인(아티제 50%, 쿠차라 30%)
                  <br />
                  - 검강검진(전 직원 대상)
                  <br />
                  - 명절 선물 지급
                  <br />- 생일 쿠폰 지급
                </p>
              </div>
              <div className={cx('term')}>
                <div className={cx('title')}>[채용절차]</div>
                <p className={cx('content')}>웹사이트 입사지원 접수 > 면접 > 매장 발령</p>
              </div>
              <Agreement />
              <div className={cx('buttonWrapper')}>
                <div className={cx('checkWrapper')}>
                  <div className={cx('checkBox')} onClick={this.toggleCheck}>
                    {isAgree && <Ionicon icon="md-checkmark" fontSize="17px" />}
                  </div>
                  <div className={cx('agreeText')}>상기 개인정보 수집 및 이용에 동의합니다.</div>
                </div>
                <div className={cx('sendButton')} onClick={this.applyForm}>
                  <div className={cx('text')}>{isProcessing ? '등록 중' : '접수하기'}</div>
                  <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  selectBrand = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({ selectedType: idx });
    }
  };

  selectRole = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({ selectedRole: idx });
    }
  };

  toggleCheck = () => {
    const { isAgree, isProcessing } = this.state;

    // 등록 중이 아닌 경우
    if (!isProcessing) {
      this.setState({ isAgree: !isAgree });
    }
  };

  applyForm = () => {
    const { selectedType, selectedRole, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    // 입력내용 검증을 통과한 경우
    if (this.validateForm()) {
      this.setState({ isProcessing: true });

      axios
        .post('https://flymail.apis.flyground.co.kr/send', {
          key: '986ec6d5-dd01-4b4e-8709-d6654d5381e0',
          to: ['kgb@bonavie.co.kr'],
          subject: '입사지원 접수',
          html: `
            <ul>
              <li>직무 : ${types[selectedType]}</li>
              <li>구분 : ${roles[selectedRole]}</li>
              <li>지원매장 : ${this.storeSelector.getSelectedStore().label}</li>
              <li>성명 : ${this.nameInput.current.value}</li>
              <li>이메일 : ${this.emailInput.current.value}</li>
              <li>연락처 : ${this.telInput.current.value}</li>
            </ul>
            <br />
            <p>${this.contentInput.current.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')}</p>
          `,
        })
        .then(() => {
          alert('정상적으로 등록이 완료되었습니다.');
          navigate('/'); // 등록 완료 후 홈 이동
        });
    }
  };

  validateForm = () => {
    const { isAgree } = this.state;

    const selectedStore = this.storeSelector.getSelectedStore();
    const name = this.nameInput.current.value.trim();
    const email = this.emailInput.current.value.trim();
    const tel = this.telInput.current.value.trim();
    const content = this.contentInput.current.value.trim();

    // 지원매장
    if (selectedStore === null) {
      alert('지원매장을 선택해주세요.');
      return false;
    }

    // 성명
    if (name === '') {
      alert('성명을 입력해주세요.');
      this.nameInput.current.focus();
      return false;
    }

    // 이메일
    if (email === '') {
      alert('이메일을 입력해주세요.');
      this.emailInput.current.focus();
      return false;
    } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      alert('이메일 형식을 확인해주세요.');
      this.emailInput.current.focus();
      return false;
    }

    // 전화번호
    if (tel === '') {
      alert('전화번호를 입력해주세요.');
      this.telInput.current.focus();
      return false;
    } else if (!/^[0-9]+$/.test(tel)) {
      alert('전화번호 형식을 확인해주세요.');
      this.telInput.current.focus();
      return false;
    }

    // 지원동기
    if (content === '') {
      alert('지원동기를 입력해주세요.');
      this.contentInput.current.focus();
      return false;
    }

    // 동의
    if (!isAgree) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      return false;
    }

    return true;
  };
}

export default RecruitForm;
